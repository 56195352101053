import React from 'react';

import Layout from '../../../components/layout';
import Container from '../../../components/container';
import ResidenceDetails from '../../../components/residence-details';

import Process1 from '../../../images/work/22_CASA_LA_DONA/PROCESS/CASA_01.jpg';
import Process2 from '../../../images/work/22_CASA_LA_DONA/PROCESS/CASA_02.jpg';
import Process3 from '../../../images/work/22_CASA_LA_DONA/PROCESS/CASA_03.jpg';
import Process1s from '../../../images/work/22_CASA_LA_DONA/PROCESS/450X280/CASA_SM_01.jpg';
import Process2s from '../../../images/work/22_CASA_LA_DONA/PROCESS/450X280/CASA_SM_02.jpg';
import Process3s from '../../../images/work/22_CASA_LA_DONA/PROCESS/450X280/CASA_SM_03.jpg';

const Residence22 = () => (
  <Layout>
    <Container>
      <ResidenceDetails
        residenceTitle="CASA LA DONA"
        residenceLocation="PEBBLE BEACH, CALIFORNIA"
        infoList={[
          'Remodel of existing 3,300 SF single family residence. Addition of 1,200 SF of living space at the main level, 500 SF of garage, and 900 SF of ADU above the garage to a total of 5,900 SF.',
          'Design - 2020',
          'Construction - 2020-2021 (Under construction)',
          'General Contractor: Carmel Builders, Inc.',
          'Structural Engineer: Structures Engineering, Inc.',
        ]}
        testimonialList={[]}
        photos={[]}
        photosSmall={[]}
        processPhotos={[Process1, Process2, Process3]}
        processPhotosSmall={[Process1s, Process2s, Process3s]}
      />
    </Container>
  </Layout>
);

export default Residence22;
